import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
const Varpersonal = (props) => (
  <Layout>
    <Seo title='Personal' />
    <PageHeader text='Våra medarbetare' />
    <div className='container'>
      <div className='row'>

      {/* Per-Erik Gustafsson */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2'>
          <div className='card border-0'>
            <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='Per-Eri' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Per-Erik Gustafsson</h4>
              <p className='card-text text-center'>Skolchef <br /> Utbildningsledare <br />Trafiklärare på behörighet B</p>
            </div>
          </div>
        </div>

        {/* Johanna Gustafsson */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2'>
          <div className='card border-0'>
            <Img fluid={props.data.imageThree.childImageSharp.fluid} alt='Johanna' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Johanna Gustafsson</h4>
              <p className='card-text text-center'>Reception</p>
            </div>
          </div>
        </div>

        {/* Martin Karimi */}
        {/* <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2'>
          <div className='card border-0'>
            <Img fluid={props.data.imageFour.childImageSharp.fluid} alt='Martin' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Martin Karimi</h4>
              <p className='card-text text-center'>Reception <br />Blivande trafiklärare</p>
            </div>
          </div>
        </div> */}

        {/* Victor Gustafsson */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2'>
          <div className='card border-0'>
            <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt='Victor' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Victor Gustafsson</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B <br />Riskutbildare del 2</p>
            </div>
          </div>
        </div>

        {/* Anna-Karin "Anki" Åsén */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageFive.childImageSharp.fluid} alt='Anna-Karin' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Anna-Karin "Anki" Åsén</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B <br /> Introduktionsutbildare <br />Riskutbildare del 1</p>
            </div>
          </div>
        </div>


        {/* Ariman Atroushi */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2'>
          <div className='card border-0'>
            <Img fluid={props.data.imageEight.childImageSharp.fluid} alt='Ariman' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Ariman Atroushi</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B</p>
            </div>
          </div>
        </div>

        {/* Perry Wall */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageNine.childImageSharp.fluid} alt='Perry' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Perry Wall</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B</p>
            </div>
          </div>
        </div>

        {/* Elias Njie */}
        {/* <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageTen.childImageSharp.fluid} alt='Elias' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Elias Njie</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B</p>
            </div>
          </div>
        </div> */}

        {/* Ingemar Grahn */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageEleven.childImageSharp.fluid} alt='Ingemar' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Ingemar Grahn</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B</p>
            </div>
          </div>
        </div>
        {/* Aras Arifson */}
        {/* <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageThirteen.childImageSharp.fluid} alt='Aras' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Aras Arifson</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B <br /> Introduktionsutbildare <br />Riskutbildare del 1</p>
            </div>
          </div>
        </div> */}

        {/* Anna Wendelin */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageSeven.childImageSharp.fluid} alt='Anna' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Anna Wendelin</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B <br /> Introduktionsutbildare <br />Riskutbildare del 1</p>
            </div>
          </div>
        </div>

        {/* Karin Magnusson */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageTwelve.childImageSharp.fluid} alt='Karin' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Karin Magnusson</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B</p>
            </div>
          </div>
        </div>

        
        {/* Joakim Bölander */}
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imagefourteen.childImageSharp.fluid} alt='Joakim' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Joakim Bölander</h4>
              <p className='card-text text-center'>Trafiklärare på behörighet B</p>
            </div>
          </div>
        </div>

     {/* Agnes Garamvölgyi */}
     <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
        <div className='card border-0'>
          <Img fluid={props.data.imageSix.childImageSharp.fluid} alt='Agnes' className='rounded card-img-top' />
          <div className='card-body'>
            <h4 className='card-title text-center'>Agnes</h4>
            <p className='card-text text-center'>Reception</p>
          </div>
        </div>
      </div>

             {/* Mardin */}
             <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2 '>
          <div className='card border-0'>
            <Img fluid={props.data.imageSeven.childImageSharp.fluid} alt='Anna' className='rounded card-img-top' />
            <div className='card-body'>
              <h4 className='card-title text-center'>Martin</h4>
              <p className='card-text text-center'>Reception</p>
            </div>
          </div>
        </div>



      </div>
    </div>
  </Layout>
)

export default Varpersonal

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "personalen/pelle.png" }) {
      childImageSharp {
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageTwo: file(relativePath: { eq: "personalen/victor.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageThree: file(relativePath: { eq: "personalen/Johanna.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageFour: file(relativePath: { eq: "personalen/mardin.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageFive: file(relativePath: { eq: "personalen/anki.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageSix: file(relativePath: { eq: "personalen/personal.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    imageSeven: file(relativePath: { eq: "personalen/personal.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageEight: file(relativePath: { eq: "personalen/arman.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageNine: file(relativePath: { eq: "personalen/perry.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageTen: file(relativePath: { eq: "personalen/elias.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageEleven: file(relativePath: { eq: "personalen/ingmar.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    
    imageTwelve: file(relativePath: { eq: "personalen/personal.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageThirteen: file(relativePath: { eq: "personalen/aras.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imagefourteen: file(relativePath: { eq: "personalen/personal.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }


} 
`
